import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby'
import Layout from '../components/Layout';
import Mapbox from '../mapbox'
import LegendCard from '../mapbox/LegendCard'
import RefDataDate from '../components/RefDataDate';
import DrilldownModal from '@src/components/DrilldownModal';
import Calculate from '../util/calculate';

import { Select } from 'antd';
import imageStudentsVEC from '/images/vec-1.png';
import imageStudentsVECHelp from '/images/vec-8.png';
import imageKRT3 from '/images/vec-6.png';
import ImageBG from '/images/wepik-photo-mode-2022625-111621.png';

import imageMapKRT from '/images/vec-5.png';
import imageSchoolKRT from '/images/vec-7.png';
import target_vec_2 from '/images/vec-2.png'
import target_vec_3 from '/images/vec-3.png'

import Star from '/images/university.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useTranslation from '../hook/useTranslation'
import TumbonAddr from '../util/TumbonAddr'
import region from '../util/region';
import api from '../util/api';
import getProvinceName from '../util/getProvinceName'
import './index.css';

const { Option } = Select;
const PageVEC = (props) => {
  const [initData, setInitData] = useState(null)
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);
  const [T, setLang, lang] = useTranslation()
  const [data, setData] = React.useState({
    projectResultProvince: 0,
    projectResultSchool: 0,
    helpStudents: 0,
    target2566: 0,
    target2565: 0,
    target2564: 0,
    target2563: 0,
    target2562: 0,
    percentY2566: 0,
    percentY2565: 0,
    percentY2564: 0,
    percentY2563: 0,
    percentY2562: 0,
    controlProvince: 0,
    academyjoin: 0,
    major: 0,
    drilldown : {
      controlProvince : []
    }
  });

  const [filter, setFilter] = React.useState({
    minView: 'country',
    view: 'country',
    year: "2566",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });

  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: ''
  })

  useEffect(async () => {
    let is_login = api.isLogin().status
    let res_profile = await (is_login ? api.getProfileDb() : null)
    let res_vec = await api.getInitData('vec')
    console.log('res_vec.deepdata',res_vec.deepdata)
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId
        let {
          academyJoin2566,
          coverProvince2565,
          coverProvince2566,
          helpStudents2562,
          helpStudents2563,
          helpStudents2564,
          helpStudents2565,
          helpStudents2566,
          major2566,
          university2565,
        } = res_vec.deepdata.province[getProvinceName(pid)]
        let max = Math.max(helpStudents2566, helpStudents2565, helpStudents2564, helpStudents2563, helpStudents2562, 1)
        data['helpStudents'] = helpStudents2566
        data['target2566'] = helpStudents2566
        data['target2565'] = helpStudents2565
        data['target2564'] = helpStudents2564
        data['target2563'] = helpStudents2563
        data['target2562'] = helpStudents2562
        data['controlProvince'] = coverProvince2566
        data['academyjoin'] = academyJoin2566
        data['major'] = Object.values(major2566).length

        data['percentY2566'] = helpStudents2566 / max * 80
        data['percentY2565'] = helpStudents2565 / max * 80
        data['percentY2564'] = helpStudents2564 / max * 80
        data['percentY2563'] = helpStudents2563 / max * 80
        data['percentY2562'] = helpStudents2562 / max * 80

        let province_sum = university2565.reduce((acc, { province_name, sum_vec }) => (acc[province_name] = (acc[province_name] || 0) + sum_vec, acc), {});
        for (const [key, value] of Object.entries(province_sum)) {
          data['drilldown']['controlProvince'].push({province_name : key, sum_vec : value})
        }

        console.log('data',data)
        setData({ ...data })
        setInitData({
          ...res_vec.deepdata.province[getProvinceName(pid)],
          university2562: res_vec.deepdata.university2562.filter(item => item.province_name === getProvinceName(pid)),
          university2563: res_vec.deepdata.university2563.filter(item => item.province_name === getProvinceName(pid)),
          university2564: res_vec.deepdata.university2564.filter(item => item.province_name === getProvinceName(pid)),
          university2565: res_vec.deepdata.university2565.filter(item => item.province_name === getProvinceName(pid)),
          university2566: res_vec.deepdata.university2566.filter(item => item.province_name === getProvinceName(pid)),
        })
        setFilter({ ...filter, minView: "province", view: "province", province_name: getProvinceName(pid) })
      } else {
        let {
          academyJoin2566,
          coverProvince2565,
          coverProvince2566,
          helpStudents2562,
          helpStudents2563,
          helpStudents2564,
          helpStudents2565,
          helpStudents2566,
          major2566,
          university2565,
        } = res_vec.deepdata
        let max = Math.max(helpStudents2566, helpStudents2565, helpStudents2564, helpStudents2563, helpStudents2562, 1)
        data['helpStudents'] = helpStudents2566
        data['target2566'] = helpStudents2566
        data['target2565'] = helpStudents2565
        data['target2564'] = helpStudents2564
        data['target2563'] = helpStudents2563
        data['target2562'] = helpStudents2562
        data['controlProvince'] = coverProvince2566
        data['academyjoin'] = academyJoin2566
        data['major'] = Object.values(major2566).length

        data['percentY2566'] = helpStudents2566 / max * 80
        data['percentY2565'] = helpStudents2565 / max * 80
        data['percentY2564'] = helpStudents2564 / max * 80
        data['percentY2563'] = helpStudents2563 / max * 80
        data['percentY2562'] = helpStudents2562 / max * 80

        let province_sum = university2565.reduce((acc, { province_name, sum_vec }) => (acc[province_name] = (acc[province_name] || 0) + sum_vec, acc), {});
        for (const [key, value] of Object.entries(province_sum)) {
          data['drilldown']['controlProvince'].push({province_name : key, sum_vec : value})
        }

        console.log('data',data)
        setData({ ...data })
        setInitData(res_vec.deepdata)
      }
    }
  }, [])

  // useEffect(async () => {
  //   let res_krt = await api.getInitData('vec')
  //   let {
  //     academyJoin2566,
  //     coverProvince2566,
  //     helpStudents2562,
  //     helpStudents2563,
  //     helpStudents2564,
  //     helpStudents2565,
  //     helpStudents2566,
  //     major2566
  //   } = res_krt.deepdata
  //   let max = Math.max(helpStudents2566, helpStudents2565, helpStudents2564, helpStudents2563, helpStudents2562, 1)
  //   data['helpStudents'] = helpStudents2566
  //   data['target2566'] = helpStudents2566
  //   data['target2565'] = helpStudents2565
  //   data['target2564'] = helpStudents2564
  //   data['target2563'] = helpStudents2563
  //   data['target2562'] = helpStudents2562
  //   data['controlProvince'] = coverProvince2566
  //   data['academyjoin'] = academyJoin2566
  //   data['major'] = Object.values(major2566).length

  //   data['percentY2566'] = helpStudents2566 / max * 80
  //   data['percentY2565'] = helpStudents2565 / max * 80
  //   data['percentY2564'] = helpStudents2564 / max * 80
  //   data['percentY2563'] = helpStudents2563 / max * 80
  //   data['percentY2562'] = helpStudents2562 / max * 80
  //   setData({ ...data })
  //   setInitData(res_krt.deepdata)
  // }, [])

  useEffect(async () => {
    if (initData) {
      let dataContextUniversity = initData['university' + filter.year]
      if (filter.region !== 'ทั้งหมด') dataContextUniversity = dataContextUniversity.filter(item => item.region === filter.region)
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        region: filter['region'],
        layer: filter.view
      }, convertMapContent(filter.view, dataContextUniversity))
      let data_university = [...new Set(dataContextUniversity)]
      if (!filter.province_name || filter.province_name !== 'ทั้งหมด') {
        if (filter.city_name !== null) {
          data_university = [...new Set(dataContextUniversity.filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
        } else {
          data_university = [...new Set(dataContextUniversity.filter(item => item.province_name === filter.province_name))]
        }
      }
      setDataMap([
        { id: 'border', layer: filter.view, data: response.data },
        { id: 'university', layer: 'marker', data: data_university.map(item => ({ ...item, type: 'university' })), icon: Star },
      ])

      let data_legend = []

      if (lang === 'EN') {
        data_legend = [
          { name: 'Number of students who received scholarships', value: response.data.reduce((acc, cur) => acc + cur.vec, 0), unit: 'stduents' },
          { name: 'Number of vocational colleges that students have enrolled in', value: response.data.reduce((acc, cur) => acc + cur.university_count, 0), unit: 'college' },
          { name: 'Number of academic majors', value: response.data.reduce((acc, cur) => acc + cur.major_count, 0), unit: 'majors' },
        ]
      } else {
        data_legend = [
          { name: 'จำนวนนักเรียนที่ได้รับทุน', value: response.data.reduce((acc, cur) => acc + cur.vec, 0), unit: 'คน' },
          { name: 'จำนวนสถาบันที่เด็กนักเรียนได้เข้ารับการศึกษา', value: response.data.reduce((acc, cur) => acc + cur.university_count, 0), unit: 'สถาบัน' },
          { name: 'จำนวนสาขาที่สนับสนุน', value: response.data.reduce((acc, cur) => acc + cur.major_count, 0), unit: 'สาขา' },
        ]
      }

      setLegendData({
        ...legend_data,
        title: T(filter.province_name, lang),
        subtitle: T(filter.city_name, lang),
        data: data_legend,
      })
    }

  }, [filter, lang, initData])

  const convertMapContent = (layer, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => { return { region: region.getRegion(item), province: item, total: 0, vec: 0, university_count: 0, major_count: 0 } });
    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], total: 0, vec: 0, university_count: 0, major_count: 0 } });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], tumbon: item.split('_')[2], total: 0, vec: 0, university_count: 0, major_count: 0 } });
    data.forEach((item) => {
      let total = (item.sum_vec)
      let count = (item.sum_vec)
      if (layer === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        if (index !== -1) {
          !province[index]['total'] ? province[index]['total'] = total : province[index]['total'] += total
          !province[index]['vec'] ? province[index]['vec'] = count : province[index]['vec'] += count
          !province[index]['university_count'] ? province[index]['university_count'] = 1 : province[index]['university_count'] += 1
          !province[index]['major_count'] ? province[index]['major_count'] = item.major_group.length : province[index]['major_count'] += item.major_group.length
        }
        res = province
      } else if (layer === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (index !== -1) {
          !city[index]['total'] ? city[index]['total'] = total : city[index]['total'] += total
          !city[index]['vec'] ? city[index]['vec'] = count : city[index]['vec'] += count
          !city[index]['university_count'] ? city[index]['university_count'] = 1 : city[index]['university_count'] += 1
          !city[index]['major_count'] ? city[index]['major_count'] = item.major_group.length : city[index]['major_count'] += item.major_group.length
        }
        res = city
      } else if (layer === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
          !tumbon[index]['vec'] ? tumbon[index]['vec'] = count : tumbon[index]['vec'] += count
          !tumbon[index]['university_count'] ? tumbon[index]['university_count'] = 1 : tumbon[index]['university_count'] += 1
          !tumbon[index]['major_count'] ? tumbon[index]['major_count'] = item.major_group.length : tumbon[index]['major_count'] += item.major_group.length
        }
        res = tumbon
      } else if (layer === 'tumbon') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (index !== -1) {
          !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
          !tumbon[index]['vec'] ? tumbon[index]['vec'] = count : tumbon[index]['vec'] += count
          !tumbon[index]['university_count'] ? tumbon[index]['university_count'] = 1 : tumbon[index]['university_count'] += 1
          !tumbon[index]['major_count'] ? tumbon[index]['major_count'] = item.major_group.length : tumbon[index]['major_count'] += item.major_group.length
        }
        res = tumbon
      }
    })
    if (filter.region !== 'ทั้งหมด' && filter.view === 'country') res = res.filter(item => item.region === filter.region)
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['vec'] / element['total'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['vec'] / result.map(item => item.vec).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })
    let data_color = []
    if (filter.region !== 'ทั้งหมด') {
      data_color = [
        { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.vec)), count: 0, text: `มาก` },
        { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.vec)), count: 0, text: `ปานกลาง` },
        { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.vec)), count: 0, text: `ปานกลาง` },
        { color: border_color[3], value: 0, count: 0, text: `น้อย` },
        { color: border_color[4], value: -100, count: 0, text: `น้อย` },
      ]
    } else {
      data_color = [
        { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.vec)), count: 0, text: `มาก` },
        { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.vec)), count: 0, text: `ปานกลาง` },
        { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.vec)), count: 0, text: `ปานกลาง` },
        { color: border_color[3], value: 0, count: 0, text: `น้อย` },
        { color: border_color[4], value: -100, count: 0, text: `น้อย` },
      ]
    }
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.vec)
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }

  const onclickBorder = (layer, filterBorder) => {
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['view'] = 'province'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
      } else if (layer === 'city' && filterBorder.city) {
        _filter['view'] = 'city'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === _filter.minView) return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  return (
    <Layout
      Role={['public-login', 'partner', 'eef', 'partner_province']}
      backgroundImage={`url(${ImageBG})`}
      getLang={(translate, lang) => {
        setLang(lang)
      }}
    >

      <div>
        <div className='h-[120px]'></div>
        <h1 className="font-bold text-2xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T("VEC_Project_TITLE", lang)}</h1>
        <div className="max-w-[1300px] 2xl:max-w-[1400px] rounded-3xl m-auto p-8 bg-white bordered">
          <div className="font-bold text-xl mb-2 text-[#0E3165]">{T("Project_Description", lang)}</div>
          <p className="text-xl mb-2 font-baijam">{T("VEC_Project_Description", lang)} </p>

          <div className="grid grid-cols-11 gap-4 w-full mt-8">

            <div className="col-span-12 lg:col-span-4 xl:col-span-3 h-[260px] lg:h-[260px]">
              <div className="font-bold text-xl mb-2 text-[#0E3165]">{T("Target_Group", lang)}</div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[260px] lg:h-[260px] p-1">
                <div className="px-6 py-4">
                  <div className='flex flex-col justify-around '>
                    <div className="flex flex-col">
                      <img className="flex self-center" src={imageStudentsVEC} alt="image for banner" width={'84px'} style={{}} />
                    </div>
                    <div className="flex-col self-center">
                      <p className="flex text-lg text-center">{T("CCT_Type_Support_CONTENT", lang)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-span-12  lg:col-span-6 xl:col-span-7 mt-8 lg:mt-0 ">
              <div className="font-bold text-xl mb-2 text-[#0E3165]">{T("Type_Support", lang)}</div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full lg:h-[260px] p-1">
                <div class="grid grid-cols-6 gap-4 p-2">

                  <div class="col-span-6">
                    <div className='flex flex-row'>
                      <img className="flex self-center mx-2" src={target_vec_2} alt="image for banner" width={'48px'} style={{}} />
                      <div className="flex flex-col">
                        <h3 className="text-xl text-orange-400 font-bold">{T("CCT_Type_Support_6500", lang)}</h3>
                        <div className="text-md text-black-500">{T("CCT_Type_Support_6500_contect", lang)}</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6">
                    <div className='flex flex-row'>
                      <img className="flex self-center mx-2" src={target_vec_2} alt="image for banner" width={'48px'} style={{}} />
                      <div className="flex flex-col">
                        <h3 className="text-xl text-orange-400 font-bold">{T("CCT_Type_Support_7500", lang)}</h3>
                        <div className="text-md text-black-500">{T("CCT_Type_Support_6500_contect", lang)}</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-span-6">
                    <div className='flex flex-row '>
                      <img className="flex self-center mx-2 " src={target_vec_3} alt="image for banner" width={'48px'} style={{}} />
                      <div className="flex flex-col">
                        <h3 className="text-xl text-orange-400 font-bold">{T("CCT_Type_Support_fees", lang)}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >


          <div className="font-bold text-xl mt-16 mb-2 text-[#0E3165] lg:mt-8">{T("VEC_OUTCOMES_PROJECT_2565", lang)}</div>
          <div className="grid grid-cols-12 gap-4 w-full">
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <DrilldownModal title={`${T("VEC_coverage_in", lang)} ${data?.controlProvince?.toLocaleString('en-US')} ${T("provinces", lang)}`} columns={[{title:'จังหวัด',key : 'province_name',align : 'left'},{title:'นักเรียนที่ได้รับทุน',key : 'sum_vec',align : 'center'}]} data={data.drilldown.controlProvince}>
                <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[182px] p-1 my-2">
                  <div className="px-6 py-4">
                    <div className='flex flex-row mx-2 my-6'>
                      <img className="flex self-center" src={imageMapKRT} alt="image for banner" width={'80px'} />
                      <div className="flex flex-col ml-4">
                        <h2 className="font-bold text-lg xl:text-xl m-0">{T("VEC_coverage_in", lang)}</h2>
                        <div className="font-bold text-xl xl:text-2xl m-0 text-[#048967]">{`${data?.controlProvince?.toLocaleString('en-US')}`}</div>
                        <div className="font-bold text-lg xl:text-xl m-0">{T("provinces", lang)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </DrilldownModal>

              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[182px] p-1 my-2">
                <div className="px-6 py-4">
                  <div className='flex flex-row mx-2 my-6'>
                    <img className="flex self-center" src={imageSchoolKRT} alt="image for banner" width={'80px'} />

                    {
                      lang === 'EN' ?
                        <div className="flex flex-col ml-4">
                          <p className="font-bold text-xl xl:text-2xl m-4 text-[#048967]">{`${data?.academyjoin?.toLocaleString('en-US')}`} institutions </p>
                        </div>
                        :
                        <div className="flex flex-col ml-4">
                          <h2 className="font-bold text-lg xl:text-xl m-0">จำนวนสถาบัน</h2>
                          <div className="font-bold text-xl xl:text-2xl m-0 text-[#048967]">
                            {`${data?.academyjoin?.toLocaleString('en-US')}`}
                          </div>
                          <div className="font-bold text-lg xl:text-xl m-0">สถาบัน</div>
                        </div>
                    }


                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[182px] p-1 my-2">
                <div className="px-6 py-4">
                  <div className='flex flex-row mx-2 my-6'>
                    <img className="flex self-center" src={imageKRT3} alt="image for banner" width={'80px'} />
                    {
                      lang === 'EN' ?
                        <div className="flex flex-col ml-4">
                          <h2 className="font-bold text-lg xl:text-xl m-0">Career opportunities in</h2>
                          <p className="font-bold text-xl xl:text-2xl m-0 text-[#048967]"> {`${data?.major?.toLocaleString('en-US')}`} job field</p>
                        </div>
                        :
                        <div className="flex flex-col ml-4">
                          <h2 className="font-bold text-lg xl:text-xl m-0">{T("VEC_caree_opportunitiesr", lang)}</h2>
                          <p className="font-bold text-xl xl:text-2xl m-0 text-[#048967]">{`${data?.major?.toLocaleString('en-US')}`}</p>
                          <p className="font-bold text-lg xl:text-xl m-0">{T("VEC_job_fileds", lang)}</p>
                        </div>
                    }

                  </div>
                </div>
              </div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[182px] p-1 my-2">
                <div className="px-6 py-4">
                  <div className='flex flex-row mx-2 my-6'>
                    <img className="flex self-center" src={imageStudentsVECHelp} alt="image for banner" width={'80px'} />

                    {
                      lang === 'EN' ?
                        <div className="flex flex-col ml-4">
                          <p className="font-bold text-xl xl:text-2xl m-0 text-[#048967]">{`${data?.target2566?.toLocaleString('en-US')}`} students</p>
                          <h2 className="font-bold text-lg xl:text-xl m-0">who received scholarships</h2>
                        </div>
                        :
                        <div className="flex flex-col ml-4">
                          <h2 className="font-bold text-lg xl:text-xl m-0">นักเรียนที่ได้รับทุน</h2>
                          <p className="font-bold text-xl xl:text-2xl m-0 text-[#048967]">{`${data?.target2566?.toLocaleString('en-US')}`}</p>
                          <p className="font-bold text-lg xl:text-xl m-0">คน</p>
                        </div>

                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 lg:col-span-5">
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full lg:h-[380px] p-1 ">
                <div className="px-6 py-4">
                  <div className="flex flex-col">
                    <div className="font-bold text-md xl:text-xl mb-2 text-center">{T("NO_STUDENTS_SCHOLARSHIPS_AY")}</div>
                  </div>
                  <div className='border-t-2 w-full h-[0.5px]'></div>
                  <div className="flex flex-col">

                    < div className="font-bold text-md xl:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2566")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2566 * 0.9 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-600 relative rounded-md`}
                      />
                      < div className="font-bold text-md ml-2 text-bla ck-800">{`${data?.target2566?.toLocaleString('en-US')}`} {T('students')}</div>
                    </div>

                    < div className="font-bold text-md xl:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2565")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2565 * 0.9 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-600 relative rounded-md`}
                      />
                      < div className="font-bold text-md ml-2 text-bla ck-800">{`${data?.target2565?.toLocaleString('en-US')}`} {T('students')}</div>
                    </div>

                    < div className="font-bold text-md xl:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2564")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2564 * 0.9 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-600 relative rounded-md`}
                      />
                      < div className="font-bold text-md ml-2 text-bla ck-800">{`${data?.target2564?.toLocaleString('en-US')}`} {T('students')}</div>
                    </div>

                    < div className="font-bold text-md xl:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2563")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2563 * 0.9 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-500 relative rounded-md`}
                      />
                      < div className="font-bold text-md ml-2 text-bla ck-800">{`${data?.target2563?.toLocaleString('en-US')}`} {T('students')}</div>
                    </div>

                    < div className="font-bold text-md xl:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2562")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2562 * 0.9 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-400 relative rounded-md`}
                      />
                      < div className="font-bold text-md ml-2 text-bla ck-800">{`${data?.target2562?.toLocaleString('en-US')}`} {T('students')}</div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="max-w rounded-xl overflow-hidden shadow-[0_0px_16px_rgba(17,17,26,0.1)] bg-white w-full mt-10 p-2">

            <header className="text-gray-600 body-font">
              <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <nav className="flex lg:w-1/5 flex-wrap items-center text-base md:ml-auto">
                </nav>
                <p className="w-3/5 items-center justify-center font-bold text-2xl mb-2  text-[#048967] text-center">
                  {T("VEC_MAP", lang)}
                </p>
                <div className="lg:w-1/5 inline-flex lg:justify-end ml-5 lg:ml-0">
                  {
                    lang !== 'EN' &&
                    <button class="inline-flex items-center bg-emerald-500 border py-1 px-3 focus:outline-none hover:bg-emerald-700 rounded-full text-base mt-4 md:mt-0 text-white"
                      onClick={() => navigate('/fullmap-vec')}>
                      สำรวจข้อมูลโครงการ
                      <svg fill="none" stroke="currentColor" s troke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                  }
                </div>
              </div >
            </header >

            <div className='border-t-2 w-full h-[0.5px] mb-4'></div>

            < div className="flex flex-col md:flex-row mb-2" >
              <div>
                <h5 className="text-sm m-0 ml-[9px]" >{T("year", lang)} : </h5>
                <Select
                  className='new-design-select'
                  showSearch
                  placeholder="ปีการศึกษา"
                  value={filter.year}
                  style={{ width: 180, margin: 4 }}
                  onChange={(val) => {
                    setFilter({ ...filter, year: val })
                  }}
                >
                  {
                    [
                      { value: '2566', text: T("Y2566") },
                      { value: '2565', text: T("Y2565") },
                      { value: '2564', text: T("Y2564") },
                      { value: '2563', text: T("Y2563") },
                      { value: '2562', text: T("Y2562") },
                    ]
                      .filter(x => x.value !== 'ไม่ระบุ')
                      .sort()
                      .map(x => <Option value={x.value}>{x.text}</Option>)
                  }
                </Select>
              </div>
              <div>
                <h5 className="text-sm m-0 ml-[9px]" >{T("region", lang)} :</h5>
                <Select
                  className='new-design-select'
                  showSearch
                  placeholder="ภาค"
                  defaultValue="ทั้งหมด"
                  value={filter.region}
                  style={{ width: 180, margin: 4 }}
                  onChange={(val) => {
                    setFilter({ ...filter, region: val })
                  }}
                >
                  <Option value={'ทั้งหมด'}>{T('ทั้งหมด', lang)}</Option>
                  {
                    initData ?
                      [...new Set(initData['university' + filter.year].map(x => x.region))]
                        .filter(x => x !== 'ไม่ระบุ')
                        .sort()
                        .map(x => <Option value={x}>{T(x, lang)}</Option>)
                      :
                      null
                  }

                </Select>
              </div>
              <div>
                <h5 className="text-sm m-0 ml-[9px]" >{T("province", lang)} : </h5>
                <Select
                  className='new-design-select'
                  showSearch
                  placeholder="เลือกจังหวัด"
                  defaultValue="ทั้งหมด"
                  value={filter.province_name}
                  style={{ width: 180, margin: 4 }}
                  onChange={(val) => {
                    setFilter({ ...filter, view: (val === 'ทั้งหมด' ? 'country' : 'province'), province_name: val })
                  }}
                >
                  <Option value={'ทั้งหมด'}>{T('ทั้งหมด', lang)}</Option>
                  {
                    initData ?
                      [
                        ...new Set(initData['university' + filter.year].filter(item => filter.region !== 'ทั้งหมด' ? item.region === filter.region : true).map(x => x.province_name))]
                        .filter(x => x !== 'ไม่ระบุ')
                        .sort()
                        .map(x => <Option value={x}>{T(x, lang)}</Option>)
                      :
                      null
                  }

                </Select>
              </div>
            </div>



            <div className="col-span-11">
              <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
                <Mapbox
                  id={'map-krt'}
                  data={data_map}
                  zoom={5.2}
                  className="fullmap-cct-container"
                  style={"border_basic"}
                  filter={filter}
                  notic_title={'จำนวนโรงเรียนในประเทศไทย'}
                  onclickBorder={onclickBorder}
                  onClickMarker={onClickMarker}
                  lang={lang}
                />
                <div className='absolute top-[10px] left-[10px] z-1'>
                  <LegendCard {...legend_data} lang={lang} />
                  <div className='justify-left mt-2 mx-auto'>
                    {filter.view !== filter.minView ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                      <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> {T("back")}
                    </button> : <></>}
                  </div>
                </div>
                <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                  <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                    <span className='font-bold center mx-1 mb-2'>{T("PROPORTION_STDUENT_RECEIVED_SCHOLARSHIP", lang)}</span>
                    <div className='flex'>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#1b5e20]`}>
                        </div>
                        <div class="font-bold text-center text-[10px] md:text-md">
                          {T("MAX")}
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#388e3c]`}>
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#66bb6a]`}>
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#a5d6a7]`}>
                        </div>
                        <div class="font-bold text-center text-[10px] md:text-md">
                          {T("MIN")}
                        </div>
                      </div>
                      <div className='ml-4  flex flex-col'>
                        <div class={`h-3 w-8 md:w-16  bg-[#94a3b8]`}>
                        </div>
                        <div class="font-bold text-center text-[10px] md:text-md">
                          {T("NO_DATA")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='absolute bottom-[10px] left-[470px] z-1'>
                  <div className='w-[400px] h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                    <span className='font-bold center mx-1 mb-2'>{T("TYPE_EDU_INSTITUTION", lang)}</span>
                    <div class="flex flex-row">
                      <div class="flex flex-row text-center ml-2">
                        <img src={Star} style={{ width: '20px', height: '20px' }} />
                        <span className='center mx-1 mb-2'>{T("PROPORTION_PERSON_RECEIVED_SCHOLARSHIP")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <RefDataDate
              title={T("VEC_REF", lang)}
              subjectTitle={T("PROCESSED_APR_2565", lang)}
            />
          </div >


        </div >
        <div className='h-[120px]'></div>
      </div >


    </Layout >
  )

}


export default PageVEC
